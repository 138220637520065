/* eslint-disable max-len */
import React from "react";

const CalenderIcon: React.FC = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.8834 4.5564H5.88342C4.77885 4.5564 3.88342 5.45183 3.88342 6.5564V20.5564C3.88342 21.661 4.77885 22.5564 5.88342 22.5564H19.8834C20.988 22.5564 21.8834 21.661 21.8834 20.5564V6.5564C21.8834 5.45183 20.988 4.5564 19.8834 4.5564Z"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.8834 2.5564V6.5564"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.88342 2.5564V6.5564"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M3.88342 10.5564H21.8834"
      stroke="black"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default CalenderIcon;
