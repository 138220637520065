/* eslint-disable max-len */
import React from "react";

const BigLocationIcon: React.FC = () => (
  <svg
    width="48"
    height="47"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M37.3063 17.536C37.3063 28.1288 22.6188 37.2083 22.6188 37.2083C22.6188 37.2083 7.93127 28.1288 7.93127 17.536C7.93127 13.9239 9.4787 10.4598 12.2331 7.90566C14.9876 5.35154 18.7234 3.91666 22.6188 3.91666C26.5141 3.91666 30.25 5.35154 33.0044 7.90566C35.7588 10.4598 37.3063 13.9239 37.3063 17.536Z"
      stroke="#9B9B9B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M26.0146 18.6041C26.0146 20.4796 24.4942 22 22.6187 22C20.7433 22 19.2229 20.4796 19.2229 18.6041C19.2229 16.7287 20.7433 15.2083 22.6187 15.2083C24.4942 15.2083 26.0146 16.7287 26.0146 18.6041Z"
      stroke="#9B9B9B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4.01459 43.0833H41.2229"
      stroke="#9B9B9B"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BigLocationIcon;
