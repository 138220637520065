import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { RootState } from "../Store/Store";

export const AuthGuard = (props: any) => {
  const { children } = props;
  const auth = useSelector((state: RootState) => state?.auth);
  const [checked, setChecked] = useState(false);
  const router = useRouter();

  useEffect(() => {
    if (!router.isReady) {
      return;
    }

    if (!auth.isAuthenticated) {
      router.push("/login");
    } else {
      setChecked(true);
    }
  }, [auth.isAuthenticated, router]);

  if (!checked) {
    return (
      <div className="loader-container">
        <div className="spinner" />
      </div>
    );
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

AuthGuard.defaultProps = {
  children: undefined,
};
