import React from "react";
import classes from "./DataBox.module.scss";
export interface PropsTypes {
  svg: JSX.Element;
  label: string;
  value: string;
}

const DataBox: React.FC<PropsTypes> = (props): JSX.Element => (
  <div className={`${classes.dataBox_container}`}>
    <div>{props.svg}</div>
    <div className={`${classes.databox_data}`}>
      <div className={`${classes.databox_label}`}>{props.label}</div>
      <div className={`${classes.databox_value}`}>{props.value}</div>
    </div>
  </div>
);

export default DataBox;
