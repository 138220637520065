/* eslint-disable max-len */
import React from "react";

const MenuDotIcon: React.FC = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_475_41383)">
      <path
        d="M7.87004 8.81218C8.23823 8.81218 8.5367 8.51371 8.5367 8.14552C8.5367 7.77733 8.23823 7.47885 7.87004 7.47885C7.50185 7.47885 7.20337 7.77733 7.20337 8.14552C7.20337 8.51371 7.50185 8.81218 7.87004 8.81218Z"
        stroke="#C2C2C2"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87004 4.14553C8.23823 4.14553 8.5367 3.84705 8.5367 3.47886C8.5367 3.11067 8.23823 2.81219 7.87004 2.81219C7.50185 2.81219 7.20337 3.11067 7.20337 3.47886C7.20337 3.84705 7.50185 4.14553 7.87004 4.14553Z"
        stroke="#C2C2C2"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.87004 13.4789C8.23823 13.4789 8.5367 13.1804 8.5367 12.8122C8.5367 12.444 8.23823 12.1455 7.87004 12.1455C7.50185 12.1455 7.20337 12.444 7.20337 12.8122C7.20337 13.1804 7.50185 13.4789 7.87004 13.4789Z"
        stroke="#C2C2C2"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_475_41383">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(0 0.107483)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default MenuDotIcon;
