/* eslint-disable max-len */
import React from "react";

const BigDriverIcon: React.FC = () => (
  <svg
    width="48"
    height="47"
    viewBox="0 0 48 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <ellipse
      cx="23.9582"
      cy="35.25"
      rx="9.79167"
      ry="9.79167"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.2498 37.2083H8.2915V28.3958L20.0415 21.5417"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M37.6667 37.2083H39.625V28.3958L27.875 21.5417"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.104 13.7083V8.8125H30.8123V13.7083C30.8123 15.3403 29.4415 18.6042 23.9582 18.6042C18.4748 18.6042 17.104 15.3403 17.104 13.7083Z"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M29.8332 8.8125C31.4652 8.8125 34.5332 8.02912 33.7499 4.89578C32.9665 1.76246 26.8957 1.95831 23.9582 1.95831C20.4787 1.9583 14.9498 2.54583 14.1665 4.89583C13.3832 7.24583 15.7985 8.48606 17.104 8.81245L29.8332 8.8125Z"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M23.9582 25.4583V35.25M23.9582 35.25L17.104 41.125M23.9582 35.25L31.7915 41.125"
      stroke="#9B9B9B"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default BigDriverIcon;
